import { get, post, put } from "helpers/axios";

import { changePasswordUrl, editAgentUrl, getAgentDetailsUrl } from "api/endpoints";

const changePassword = (data) => {
  return post(changePasswordUrl, data);
};

const editAgent = (data: any) => {
  return put(editAgentUrl, data);
};

const getAgentDetails = () => {
  return get(getAgentDetailsUrl)
}

const settingServices = {
  changePassword,
  editAgent,
  getAgentDetails
};

export default settingServices;
