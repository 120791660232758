import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';

import moment from 'moment';

//components
import Layout from 'components/layout/Layout';
import PageLoading from 'components/partials/pageLoading';
import PageTitle from 'components/partials/PageTitle';

//icns
import divider from "assets/icons/grey-divider.svg";
import arrowL from "assets/icons/arrow-left2.svg";
import pencil from "assets/icons/pencil.svg";


import { getAgentDetails } from 'store/actions';

const Profile = (props: any) => {
    const { loading, agent_details, getAgentDetails } = props;

    const navigate = useNavigate();

    let user = useSelector((state: any) => state.auth.user_data);
    let localStorageUser = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")!) : null;
    user = user ? user : localStorageUser;

    let agent_status = useSelector((state: any) => state.auth.agent_data);
    agent_status = agent_status || JSON.parse(localStorage.getItem("agent_data") || "{}");

    const status_names = {
        "on_hold": "On Hold",
        "new": "New",
        "verified": "Verified"
    }

    useEffect(() => {
        getAgentDetails();
    }, [])

    console.log('profile_afent>>>', agent_status)

    console.log('agent_details', agent_details)


    return (
        <Layout>
            <main className="px-4 pt-8 lg:pt-10 lg:px-10 dashboard-content-scroll">
                {loading ? <PageLoading title="agent details" /> :
                <>
                    <div className="flex items-center gap-x-1 mb-2 cursor-pointer w-fit" onClick={() => navigate("/settings")}>
                        <div><img src={arrowL} alt=""></img></div>
                        <p className="text-sm grey-text">Settings</p>
                    </div>
                    <p className="text-2xl mb-6">Profile</p>
                    <div className='w-full gap-x-6 lg:gap-x-0 lg:w-4/5'>
                        <div className='border-grey rounded mb-6'>
                            <div className='flex items-center justify-between bottom-divider-2 p-6 pb-4'>
                                <div className='flex items-center gap-x-2'>
                                    <p className='black-text-3 font-medium capitalize'>{user.company_name}</p>
                                    <p><img src={divider} alt="" /></p>
                                    <p className={`rounded-lg text-xs ${agent_details?.verify_status?.toLowerCase() === "on_hold" ? "text-[#AD0013] bg-[#FFEDEE]" : agent_details?.verify_status?.toLowerCase() === "new" ? "text-[#296FD8] bg-[#D0F5FF66]" : agent_details?.verify_status?.toLowerCase() === "verified" ? "text-[#005D00] bg-[#99FF7D2E]" : ""}  font-medium  py-1 px-5 capitalize`}>
                                        {(status_names[agent_details?.verify_status] || status_names[agent_status?.verify_status]) ?? "N/A"}
                                    </p>
                                </div>
                                <div>
                                    <Link
                                        to="/settings/profile-edit"
                                        className="flex items-center gap-1 ml-auto text-base"
                                    >
                                    <img src={pencil} alt="" />
                                    <span className="green-text text-sm hidden md:block">
                                        Edit details
                                    </span>
                                    </Link>
                                </div>
                            </div>
                            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-6 p-6'>
                                <div>
                                    <p className='grey-text font-light text-sm'>First Name</p>
                                    <p className='black-text-3 text-sm capitalize mt-1.5'>{user.firstname}</p>
                                </div>
                                <div>
                                    <p className='grey-text font-light text-sm'>Last Name</p>
                                    <p className='black-text-3 text-sm capitalize mt-1.5'>{user.lastname}</p>
                                </div>
                                <div>
                                    <p className='grey-text font-light text-sm'>Email</p>
                                    <p className='black-text-3 text-sm mt-1.5'>{user.email}</p>
                                </div>
                                <div>
                                    <p className='grey-text font-light text-sm'>Phone Number</p>
                                    <p className='black-text-3 text-sm capitalize mt-1.5'>{user.phone}</p>
                                </div>
                                <div>
                                    <p className='grey-text font-light text-sm'>Date Registered</p>
                                    <p className='black-text-3 text-sm capitalize mt-1.5'>{moment(user?.createdAt).format("DD-MM-YYYY")}</p>
                                </div>
                                <div>
                                    <p className='grey-text font-light text-sm'>Certificate of Incoporation Number</p>
                                    <p className='black-text-3 text-sm capitalize mt-1.5'>{agent_details?.incorporation_number}</p>
                                </div>
                                <div>
                                    <p className='grey-text font-light text-sm'>KRA Pin Number</p>
                                    <p className='black-text-3 text-sm capitalize mt-1.5'>{agent_details?.kra_pin_number}</p>
                                </div>
                                <div>
                                    <p className='grey-text font-light text-sm'>Company Address</p>
                                    <p className='black-text-3 text-sm capitalize mt-1.5'>{user?.company_address}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </main>
        </Layout>
    )
}

const mapStateToProps = (state: any) => {
    const { loading, agent_details } = state.settings;

    return { loading, agent_details };
};



export default connect(mapStateToProps, { getAgentDetails })(Profile);