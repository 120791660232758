import React, { useState } from "react";
import { useForm } from "react-hook-form";


//icons
import right from "assets/icons/arrow-right.svg";
import close from "assets/icons/close.svg";

//library
import Modal from "react-modal";
import { connect, useSelector } from "react-redux";

import { getCurrentTimestamp } from "helpers";

//components
import CustomInput from "components/textInputs/CustomInput";
import CustomTextarea from "components/textInputs/CustomTextarea";
import CustomPhoneInput from "components/textInputs/CustomPhoneInput";
import PrimaryButtons from "components/buttons/PrimaryButtons";
import OutlineButton from "components/buttons/OutlineButton";
import SuccessModal from "components/partials/SuccessModal";

import { requestCallBack } from "store/actions";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    // padding: " 1.5rem",
    // maxHeight: "calc(100vh - 100px)",
    // overflow: "auto",
    // WebkitOverflowScrolling: "touch",
    transform: "translate(-50%, -50%)",
    width: "calc(100vw - 10%)",
    borderRadius: "10px",
    border: "0.01px solid #888",
  },
  overlay: {
    zIndex: "90",
    backgroundColor: "rgba(6, 24, 2, 0.55)",
  },
};

interface ITime {
    startTime : string;
    endTime: string;
    selectedTime: string;
    setSelectedTime: React.Dispatch<React.SetStateAction<string>>;
}
const PreferredTime = ({startTime, endTime, selectedTime, setSelectedTime}: ITime) => {
    return (
        <div 
            className={`flex items-center black-text-4 text-sm font-light border-solid border-[1px] w-[111px] px-2 py-3 rounded cursor-pointer ${selectedTime === startTime + '-' + endTime ? "border-[#139C33]" : "border-[#E5E7EB]"}`}
            onClick={() => setSelectedTime(startTime + '-' + endTime)}
        >
            <span>{startTime}</span>
            <span>-</span>
            <span>{endTime}</span>
        </div>
    )
}

const AgentEnquiryModal = (props: any) => {
  const { closeModal, isOpen, loading, error, id, requestCallBack, setShowContactSuccess, setShowContactModal } = props;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [selectedTime, setSelectedTime] = useState("11:00-1:00pm");

  let user = useSelector((state: any) => state.auth.user_data);
  // @ts-ignore
  user = user ? user : JSON.parse(localStorage.getItem("user_data"));

  console.log(">>>LL", selectedTime)

  const onSuccess = () => {
    closeModal();
  }   

  const onSubmit = (data: any) => {
    const _data = {
      fullname: user.firstname + " " + user.lastname,
      email: user.email,
      contact_number: user.phone,
      reason_for_callback: "Agent Enquiry",
      best_time_to_call: selectedTime,
      message_body: data.message,
    };

    requestCallBack(_data, onSuccess)
  };

  return (
    <Modal isOpen={isOpen} style={customStyles} className={"newratemodal"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between items-center pt-6 px-6 pb-4 border-solid border-b-[1px] border-[#e6e7ec80]">
          <div>
            <p className="text-xl black-text-3 font-medium">Contact Support</p>
            {/* <p className="text-sm grey-text font-light">
              Please fill in the reqired details.
            </p> */}
          </div>
          <>
            <img
              className="cursor-pointer"
              onClick={closeModal}
              src={close}
              alt="close"
            />
          </>
        </div>
        <div className="py-4 md:py-8 px-6 flex flex-col gap-y-4 max-h-[calc(100vh_-_300px)] overflow-auto">
          <CustomInput
            control={control}
            name={"call_date"}
            id={"call_date"}
            label={"Select Preferred Call Date"}
            placeholder={""}
            isRequired={true}
            type="date"
            errors={errors}
            isDisabled={false}
            defaultValue={""}
            min={getCurrentTimestamp(1)}
            max={""}
            icon={""}
          />
          <div className="mb-3">
            <p className="text-sm font-light grey-text">What’s Your Preferred Call Time?</p>
            <div className="flex items-center gap-4 mt-1.5">
                <PreferredTime startTime="11:00" endTime="1:00pm" selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
                <PreferredTime startTime="1:30" endTime="3:30pm" selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
                <PreferredTime startTime="3:30" endTime="4:30pm" selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
                <PreferredTime startTime="4:30" endTime="6:00pm" selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            </div>
          </div>
          <CustomTextarea
            control={control}
            name={"message"}
            id={"message"}
            label={"Message"}
            placeholder={"What are your concerns?"}
            isRequired={true}
            errors={errors}
            isDisabled={false}
            defaultValue={""}
            icon=""
            style={{ height: "88px" }}
          />
        </div>
        <div className="p-4 flex flex-col gap-y-4 border-solid border-t-[1px] border-[#e6e7ec80] shadow-[0_-3px_32px_-12px_rgba(0,0,0,0.18)]">
          <PrimaryButtons
            title={"Send"}
            style={{ color: "#59725C" }}
            // onClick={closeModal}
            disabled={false}
            loading={loading}
            icon={""}
            class_names={"newratebg"}
          />
          <OutlineButton 
            title="Cancel" 
            loading={false}
            disabled={false}
            icon={""}
            onClick={closeModal}
            style={{ color: "#6B7280", width: "100%"}}
          />
        </div>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { error, loading } = state.contact;

  return { error, loading };
};

export default connect(mapStateToProps, { requestCallBack })(AgentEnquiryModal);
