import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const VerifiedRoute = ({ children }) => {
  let agent_status = useSelector((state: any) => state.auth.agent_data) || JSON.parse(localStorage.getItem("agent_data") || "{}");
  let isVerified = agent_status.verify_status.toLowerCase() === "verified" ? true : false;

  if(!isVerified){
    return <Navigate to="/dashboard" replace />;
  }

  return children;
}

export default VerifiedRoute